<template>
  <div>
    <div id="pre-builts-page">
      <h1 class="h1-title">Pre-Built Reports</h1>
      <search-bar class="margin-right margin-top-2" availableFilterMode="dealership" />
      <filter-bar />
      <table class="results-table margin-top">
        <tr class="header">
          <th>Report</th>
          <th>Description</th>
          <th>Download</th>
        </tr>
        <tr class="data-row" v-for="report in reportsData.filter(r => r.show)" v-bind:key="report">
          <td>{{ report.title }}</td>
          <td>{{ report.description }}</td>
          <td>
            <div v-if="downloading">
              <img src="/assets/img/loader.svg" alt="" class="loader" />
            </div>
            <div v-else class="flex-row">
              <div v-on:click="report.download('csv')" class="body-2-bold pointer-cursor flex-row">
                <p>csv</p>
                <img src="/assets/img/icon-download.svg" class="margin-left-025" />
              </div>
              <div v-on:click="report.download('xlsx')" class="body-2-bold pointer-cursor flex-row margin-left">
                <p>xlsx</p>
                <img src="/assets/img/icon-download.svg" class="margin-left-025" />
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>

    <no-date-filter-modal
      v-if="selectedFunction"
      @continue="noDateFilterModalContinue"
      @cancel="selectedFunction = null"
    />
  </div>
</template>

<script>
import {
  getDepartmentReport,
  getDealershipVolumeReport,
  getDealerVolumeReport,
  getTradedPurposeReport,
  getDailyReport,
  getPPSRReport,
  getResellerReport,
  getAdjustedValueReport,
  getSalesDifferenceReport,
  getCustomerWantedReport,
  getReasonsLostReport
} from "../../../api/v2/Reports.js";
import { mapActions, mapGetters, mapState } from "vuex";

import SearchBar from "../../../components/Views/v2/SearchBar.vue";
import FilterBar from "../../../components/Views/v2/FilterBar.vue";
import NoDateFilterModal from "../../../components/Modals/v2/NoDateFilterModal.vue";

export default {
  name: "PreBuilts",
  components: {
    SearchBar,
    FilterBar,
    NoDateFilterModal
  },
  data: function () {
    return {
      selectedFunction: null,
      downloading: false,
      reportsData: []
    };
  },
  methods: {
    getDepartmentReport: function (style) {
      if (this.checkEmptyDateFilters() && !this.selectedFunction) {
        this.selectedFunction = this.getDepartmentReport.bind(null, style);
        return;
      }
      this.downloading = true;
      getDepartmentReport(this.filters, style)
        .then(response => {
          this.downloading = false;
          var blob = new Blob([response.data], { type: style });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "department_report." + style;
          link.click();
        })
        .catch(error => {
          this.downloading = false;
          this.addError(error);
        });
    },
    getDealershipVolumeReport: function (style) {
      if (this.checkEmptyDateFilters() && !this.selectedFunction) {
        this.selectedFunction = this.getDealershipVolumeReport.bind(null, style);
        return;
      }
      this.downloading = true;
      getDealershipVolumeReport(this.filters, style)
        .then(response => {
          this.downloading = false;
          var blob = new Blob([response.data], { type: style });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "dealership_volume_report." + style;
          link.click();
        })
        .catch(error => {
          this.downloading = false;
          this.addError(error);
        });
    },
    getDealerVolumeReport: function (style) {
      if (this.checkEmptyDateFilters() && !this.selectedFunction) {
        this.selectedFunction = this.getDealerVolumeReport.bind(null, style);
        return;
      }
      this.downloading = true;
      getDealerVolumeReport(this.filters, style)
        .then(response => {
          this.downloading = false;
          var blob = new Blob([response.data], { type: style });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "dealer_volume_report." + style;
          link.click();
        })
        .catch(error => {
          this.downloading = false;
          this.addError(error);
        });
    },
    getTradedPurposeReport: function (style) {
      if (this.checkEmptyDateFilters() && !this.selectedFunction) {
        this.selectedFunction = this.getTradedPurposeReport.bind(null, style);
        return;
      }
      this.downloading = true;
      getTradedPurposeReport(this.filters, style)
        .then(response => {
          this.downloading = false;
          var blob = new Blob([response.data], { type: style });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "traded_purpose_report." + style;
          link.click();
        })
        .catch(error => {
          this.downloading = false;
          this.addError(error);
        });
    },
    getDailyReport: function (style) {
      if (this.checkEmptyDateFilters() && !this.selectedFunction) {
        this.selectedFunction = this.getDailyReport.bind(null, style);
        return;
      }
      this.downloading = true;
      getDailyReport(this.filters, style)
        .then(response => {
          this.downloading = false;
          var blob = new Blob([response.data], { type: style });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "daily_report." + style;
          link.click();
        })
        .catch(error => {
          this.downloading = false;
          this.addError(error);
        });
    },
    getPPSRReport: function (style) {
      if (this.checkEmptyDateFilters() && !this.selectedFunction) {
        this.selectedFunction = this.getPPSRReport.bind(null, style);
        return;
      }
      this.downloading = true;
      getPPSRReport(this.filters, style)
        .then(response => {
          this.downloading = false;
          var blob = new Blob([response.data], { type: style });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "ppsr_report." + style;
          link.click();
        })
        .catch(error => {
          this.downloading = false;
          this.addError(error);
        });
    },
    getResellerReport: function (style) {
      if (this.checkEmptyDateFilters() && !this.selectedFunction) {
        this.selectedFunction = this.getResellerReport.bind(null, style);
        return;
      }
      this.downloading = true;
      getResellerReport(this.filters, style)
        .then(response => {
          this.downloading = false;
          var blob = new Blob([response.data], { type: style });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "reseller_report." + style;
          link.click();
        })
        .catch(error => {
          this.downloading = false;
          this.addError(error);
        });
    },
    getAdjustedValueReport: function (style) {
      if (this.checkEmptyDateFilters() && !this.selectedFunction) {
        this.selectedFunction = this.getAdjustedValueReport.bind(null, style);
        return;
      }
      this.downloading = true;
      getAdjustedValueReport(this.filters, style)
        .then(response => {
          this.downloading = false;
          var blob = new Blob([response.data], { type: style });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "adjusted_value_report." + style;
          link.click();
        })
        .catch(error => {
          this.downloading = false;
          this.addError(error);
        });
    },
    getSalesDifferenceReport: function (style) {
      if (this.checkEmptyDateFilters() && !this.selectedFunction) {
        this.selectedFunction = this.getSalesDifferenceReport.bind(null, style);
        return;
      }
      this.downloading = true;
      getSalesDifferenceReport(this.filters, style)
        .then(response => {
          this.downloading = false;
          var blob = new Blob([response.data], { type: style });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "sales_difference_report." + style;
          link.click();
        })
        .catch(error => {
          this.downloading = false;
          this.addError(error);
        });
    },
    getCustomerWantedReport: function (style) {
      if (this.checkEmptyDateFilters() && !this.selectedFunction) {
        this.selectedFunction = this.getCustomerWantedReport.bind(null, style);
        return;
      }
      this.downloading = true;
      getCustomerWantedReport(this.filters, style)
        .then(response => {
          this.downloading = false;
          var blob = new Blob([response.data], { type: style });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "customer_wanted_report." + style;
          link.click();
        })
        .catch(error => {
          this.downloading = false;
          this.addError(error);
        });
    },
    getReasonsLostReport: function (style) {
      if (this.checkEmptyDateFilters() && !this.selectedFunction) {
        this.selectedFunction = this.getReasonsLostReport.bind(null, style);
        return;
      }
      this.downloading = true;
      getReasonsLostReport(this.filters, style)
        .then(response => {
          this.downloading = false;
          var blob = new Blob([response.data], { type: style });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "reason_lost_report." + style;
          link.click();
        })
        .catch(error => {
          this.downloading = false;
          this.addError(error);
        });
    },
    checkEmptyDateFilters: function () {
      return !(this.filters.date_time_created_gt || this.filters.date_time_created_lt);
    },
    noDateFilterModalContinue: function () {
      this.selectedFunction();
      this.selectedFunction = null;
    },
    hasPPSRReportAccess: function () {
      if (this.currentDealership) {
        return !this.currentDealership.hide_ppsr_report;
      }
      return false;
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  computed: {
    ...mapGetters({
      filters: "formStore/getFilters"
    }),
    ...mapState({
      currentDealership: state => state.dealershipStore.currentDealership
    })
  },
  mounted: function () {
    this.checkEmptyDateFilters();
    this.reportsData = [
      {
        title: "Department Report",
        description: "A breakdown of conversion percentage by month, department and user.",
        download: this.getDepartmentReport,
        show: true
      },
      {
        title: "Dealership Volume Report",
        description: "A breakdown of conversion percentage by month for your dealership(s).",
        download: this.getDealershipVolumeReport,
        show: true
      },
      {
        title: "User Volume Report",
        description: "A breakdown of conversion percentage by month for your users.",
        download: this.getDealerVolumeReport,
        show: true
      },
      {
        title: "Traded Purpose Report",
        description:
          "A breakdown of appraisals by department that have been lost or traded and the purpose behind those traded appraisals.",
        download: this.getTradedPurposeReport,
        show: true
      },
      {
        title: "Daily Report",
        description: "A breakdown of your latest appraisals, and compared with those done 3 days before.",
        download: this.getDailyReport,
        show: true
      },
      {
        title: "PPSR Report",
        description: "A breakdown of your PPSRs and who did them.",
        download: this.getPPSRReport,
        show: this.hasPPSRReportAccess()
      },
      {
        title: "Wholesaler Report",
        description: "A breakdown of your wholesalers and which of them are pricing, passing or winning.",
        download: this.getResellerReport,
        show: true
      },
      {
        title: "Adjusted Value Report",
        description: "A breakdown of your traded offers and if they've been adjusted.",
        download: this.getAdjustedValueReport,
        show: true
      },
      {
        title: "Adjusted Traded Value Report",
        description: "A breakdown of the adjusted value compared to the price sent to sales.",
        download: this.getSalesDifferenceReport,
        show: true
      },
      {
        title: "Customer Wanted Report",
        description: "A breakdown of the appraisals that were lost because the Customer wanted more money.",
        download: this.getCustomerWantedReport,
        show: true
      },
      {
        title: "Reason Lost Report",
        description: "A breakdown of the appraisals that were lost and why they were lost.",
        download: this.getReasonsLostReport,
        show: true
      }
    ];
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";

#pre-builts-page {
  padding-top: $PADDING_X2_05;
  padding-left: $PADDING_X4;
}

.loader {
  height: 15px;
}
</style>
